<!--
 * @Description: 导航栏
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 13:58:20
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-07-30 17:07:41
-->
<template>
    <div class="header" :class="scroll === 0 ? '' : 'headerScroll'">
        <div class="flex-row items-center">
            <div class="logo">
                <img :src="$IMG_PUBLIC_PATH + 'logo-site.png'"/>
            </div>
            <div class="flex-row navList" v-for="(item,index) in navItems" :key="index">
                <router-link v-if="item.target == '_self'" :to="item.path" :target="item.target">{{item.name}}</router-link>
                <a v-else :href="item.path" :target="item.target">{{item.name}}</a>
            </div>
        </div>
        <div class="flex-row items-center">
            <a href="https://ai.aiprb.com/signin" target="_blank">登录</a>
            <a href="https://ai.aiprb.com/signin" class="ml-40 primary">立即注册</a>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref} from 'vue'
import { useRouter } from 'vue-router';
const router = useRouter();
const currentPath = ref(router.currentRoute.value.path)
const navItems = ref([
    {
        name:'产品',
        path:'/product',
        target:'_self'
    },
    {
        name:'价格',
        path:'/pricing',
        target:'_self'
    },
    {
        name:'解决方案',
        path:'/solution',
        target:'_self'
    },
    {
        name:'人工智能教育',
        path:'/aiEdu',
        target:'_self'
    },
    {
        name:'帮助中心',
        path:'http://help.aiprb.com/',
        target:'_blank'
    },
        {
        name:'关于我们',
        path:'http://help.aiprb.com/aiprb-introduce/about-us.html',
        target:'_blank'
    }
])
const scroll = ref(0)
onMounted(async ()=>{
    
    console.log(currentPath.value)

    window.addEventListener('scroll', handleScroll)
})

const handleScroll = async () => {
    const scrollTop = window.scrollY
    if(scrollTop - 60 < 0) {
        scroll.value = 0
    }else{
        scroll.value = 1
    }

}
</script>
<style lang="less" scoped>
.header{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #212332;
    background: rgba(255,255,255,0.3);
    padding-left: 24px;
    padding-right: 24px;
}
.headerScroll{
    background: #fff;
}
.logo{
    width: 96px;
    height: 40px;
    img{
        width: 100%;
        height: 100%;
    }
}
.navList{
    a{
        padding-left: 40px;
        color: #212332;
        text-decoration: none;
    }
    a:first-child{
        padding-left: 50px;
    }
    a:hover,a.active,.router-link-active{
        font-weight: bold;
        color: #155EEF;
    }
}
</style>