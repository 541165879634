<!--
 * @Description: 
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-12 17:53:04
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-08-06 15:51:42
-->
<template>
<div class="container flex-col">
    <div class="container flex-row justify-between items-start">
        <div class="flex-col">
            <img :src="$IMG_PUBLIC_PATH + 'website/logo-big%402x.png'" class="logo-big" />
            <div class="flex-row mt-40">
                <div class="flex-col justify-center mr-20">
                    <div class="third-item">
                        <img :src="$IMG_PUBLIC_PATH + 'website/douyin.png'" />
                    </div>
                    <span class="mt-15">抖音</span>
                </div>
                <div class="flex-col justify-center mr-20">
                    <div class="third-item">
                        <img :src="$IMG_PUBLIC_PATH + 'website/vx.png'" />
                    </div>
                    <span class="mt-15">微信公众号</span>
                </div>
                <div class="flex-col justify-center mr-20">
                    <div class="third-item">
                        <img :src="$IMG_PUBLIC_PATH + 'website/kuaishou.png'" />
                    </div>
                    <span class="mt-15">快手</span>
                </div>
                <div class="flex-col justify-center mr-20">
                    <div class="third-item">
                        <img :src="$IMG_PUBLIC_PATH + 'website/xhs.png'" />
                    </div>
                    <span class="mt-15">小红书</span>
                </div>

            </div>
        </div>
        <div class="flex-row justify-between">
            <div class="flex-col text-left footer-item">
                <span class="font-main">产品</span>
                <router-link to='/pricing' class="mt-30 document">LLM Agent</router-link>
                <router-link to="/pricing" class="mt-20 document">Al Workflow</router-link>
                <router-link to="/pricing" class="mt-20 document">Prompt IDE</router-link>
                <router-link to="/pricing" class="mt-20 document">RAG Pipeline</router-link>
                <router-link to="/pricing" class="mt-20 document">Enterprise LLMOps</router-link>
            </div>
            <div class="flex-col text-left footer-item">
                <span class="font-main">资源</span>
                <a href="https://help.aiprb.com/" target="_blank" class="mt-30 pl-0">文档</a>
                <!-- <span class="mt-20">博客</span> -->
                <a href="https://help.aiprb.com/aiprb-introduce/contant-us.html" target="_blank" class="mt-20 pl-0">服务支持</a>
                <!-- <span class="mt-20">产品路线图</span> -->
            </div>
            <div class="flex-col text-left footer-item">
                <span class="font-main">公司</span>
                <router-link to="/document?name=service" class="mt-30 document">服务条款</router-link>
                <router-link to="/document?name=privacy" class="mt-20 document">隐私政策</router-link>
                <!-- <span class="mt-20">服务状态</span> -->
                <a href="http://help.aiprb.com/aiprb-introduce/about-us.html" target="_blank" class="mt-20 document">联系我们</a>
            </div>
        </div>

    </div>
    <div class="container copyright mt-40 flex-row items-center justify-between">
        <span>浙ICP备案号：2024084829号-2，©️上海算模算样科技有限公司</span>
        <div class="footer-nav">
            <a href="https://help.aiprb.com/" target="_blank">帮助</a>
            <a href="https://help.aiprb.com/aiprb-introduce/contant-us.html" target="_blank">举报</a>
            <a href="https://help.aiprb.com/aiprb-introduce/contant-us.html" target="_blank">用户反馈</a>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name:'airbFooter',
    data(){
        return {

        }
    }
}
</script>
<style lang="less" scoped>
    .logo-big{
        width: 190px;
        height: 48px;
    }
    .footer-item{
        padding: 0 0 0 120px;
        .font-main{
            font-size: 16px;
            font-weight: 600;
        }
        .font-text{
            
        }
    }
    .copyright{
        border-top: 1px solid #EBEBEB;
        padding: 20px 0;
        color: #535666;
        a{
            font-size: 12px;
            padding-left: 40px;
            color: #212332;
            text-decoration: none;
        }
    }
    .third-item{
        width: 108px;
        height: 108px;
        img{
            width: 100%;
            height: 100%;
            // background: #FFFFFF;
            border-radius: 8px;
            // border: 1px solid #EBEBEB;
        }
    }
</style>