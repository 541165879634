import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/common.less"
import {IMG_PUBLIC_PATH} from './utils/constants.js'



const app = createApp(App)


app.config.globalProperties.$IMG_PUBLIC_PATH = IMG_PUBLIC_PATH
app.use(store)
app.use(router)
app.mount('#app')
