<!--
 * @Description: 产品介绍
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-13 08:47:38
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-08-05 15:41:56
-->
<template>
<div class="flex-col">
    <header-nav />
    <div class="product-1">
        <div class="container flex-col items-center justify-center">
            <span class="fs-64">开箱即用的企业级 AI 能力构建平台</span>
            <span class="fs-18-thin">打通大模型 AI 能力和企业需求场景的通道，企业能够通过自主导入数据、自由编排、集成连接来获得企业级 AI 能力，让企业能够用上、用好 AI 能力</span>
            <a href="https://img.aiprb.com/%E7%BA%A2%E7%86%8A%E5%A4%9A%E6%A8%A1%E6%80%81%E5%A4%A7%E6%A8%A1%E5%9E%8B%E4%BB%8B%E7%BB%8D%E5%8F%8A%E6%A1%88%E4%BE%8B%E5%B1%95%E7%A4%BA.pdf" target="_blank" class="primary large fs-18 mt-80">立即注册</a>
            <div class="product-illustration-1 mt-130">
                <div class="small-box"></div>
            </div>
        </div>
    </div>
    <div class="container mt-80 flex-row justify-between">
        <div class="flex-col text-left">
            <span class="text-6"><span class="font-main">6</span>  大核心功能</span>
            <span class="fs-20-thin mt-20">涵盖了构建生成式 AI 原生应用所需的核心技术栈，开发者可以聚焦于创造应用的核心价值</span>
            <div class="flex-row mt-70 justify-between">
                <div class="function-box flex-col">
                    <span class="flex-row items-center"><img :src="$IMG_PUBLIC_PATH + 'website/icon-modalList%402x.png'" class="icon-48"/><span class="fs-16-fat ml-10">多模型管理</span></span>
                    <span>支持多种主流模型，按需调用，无缝切换，实现业务层和模型层解耦</span>
                </div>
                <div class="function-box flex-col ml-20">
                    <span class="flex-row items-center"><img :src="$IMG_PUBLIC_PATH + 'website/icon-drag%402x.png'" class="icon-48"/><span class="fs-16-fat ml-10">RAG 能力</span></span>
                    <span>提供高可靠的私有化数据集解决方案，为大模型补充企业私有化知识</span>
                </div>
            </div>
            <div class="flex-row justify-between mt-20">
                <div class="function-box flex-col">
                    <span class="flex-row items-center"><img :src="$IMG_PUBLIC_PATH + 'website/icon-workflow%402x.png'" class="icon-48"/><span class="fs-16-fat ml-10">编排能力</span></span>
                    <span>可视化工作流编排系统，适应更复杂的业务场景</span>
                </div>
                <div class="function-box flex-col ml-20">
                    <span class="flex-row items-center"><img :src="$IMG_PUBLIC_PATH + 'website/icon-router%402x.png'" class="icon-48"/><span class="fs-16-fat ml-10">精细成本核算</span></span>
                    <span>统计用量，做到成本可评估、可控制</span>
                </div>
            </div>
            <div class="flex-row justify-between mt-20">
                <div class="function-box api flex-col">
                    <div class="flex-row items-center justify-between">
                        <div class="flex-row items-center">
                            <img :src="$IMG_PUBLIC_PATH + 'website/icon-api%402x.png'" class="icon-48"/><span class="fs-16-fat ml-10">API 能力</span>
                        </div>
                        <img :src="$IMG_PUBLIC_PATH + 'website/arrow-question%402x.png'" class="icon-18"/>
                    </div>
                    <span class="text-white">提供利于集成的 API，轻松接入各类系统</span>
                </div>
                <div class="function-box flex-col ml-20">
                    <span class="flex-row items-center"><img :src="$IMG_PUBLIC_PATH + 'website/icon-share%402x.png'" class="icon-48"/><span class="fs-16-fat ml-10">分享能力</span></span>
                    <span>快速搭建演示 Demo，将创意分享给潜在用户</span>
                </div>
            </div>
        </div>
        <div class="product-illustration-2 ml-20 flex-col justify-start text-left">
            <!-- <div class="flex-row"> -->
                <img :src="$IMG_PUBLIC_PATH + 'website/logo-onlyHead%402x.png'" class="icon-40"/>
                <span class="mt-20 text-sec">可视化编排生成式 AI 应用的专业工作站</span><span class="text-sec">All in One Place</span>
            <!-- </div> -->
            
            <img :src="$IMG_PUBLIC_PATH + 'website/6-1.png'" class="mt-20 img-full"/>
            <img :src="$IMG_PUBLIC_PATH + 'website/6-2.png'" class="img-full"/>
        </div>
    </div>
    <div class="container-full product-2 flex-col">
        <div class="container  flex-col items-center">
            <span class="fs-36"><span class="text-main">私有化部署 ·</span>体验高效能 LLM</span>
            <span class="fs-20-thin w-880 mt-30">高可靠性、合规、数据安全，通过积墨 AI 的私有化部署解决方案，对流程和工具进行智能升级实现千人千面的用户体验</span>
        </div>
        <!-- <transition v-for="(item,i) in list" :key="i"> -->
        <div class="container product-2-1 mt-60 flex-col items-start" v-for="(item,i) in list" :key="i" v-show="i === selectIndex">
            <span class="fs-28 text-main">{{item.mainName}}</span>
            <span class="fs-28">{{item.name}}</span>
            <span class="fs-16-thin w-600 mt-30 text-left">{{item.desc}}</span>
            <div class="flex-row w-full  mt-20 justify-between">
                <div class="flex-row justify-between mt-300 w-percent-30">
                    <div class="flex-row">
                        <span class="arrow-pre" @click="handlePrivateDeployment('pre',i)"></span>
                        <span class="arrow-next ml-20" @click="handlePrivateDeployment('next',i)"></span>
                    </div>
                    <!-- <div class="flex-row">
                        <span class="arrow-next" @click="handlePrivateDeployment('next',i)"></span>
                    </div> -->
                </div>

            </div>
            <transition v-for="(item,i) in list" :key="i">
                <div class="product-2-1-2 ml-20" v-show="i === selectIndex">
                    <img :src="item.imgPath" />
                </div>
            </transition>
        </div>
        <!-- <div class="container"> -->

        <!-- </div> -->
        <!-- </transition> -->
    </div>
    <regbox-footer/>
    <airb-footer class="mt-60"/>
</div>
</template>
<script>
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'

export default {
    name:"produceView",
    components:{
        headerNav,airbFooter,regboxFooter
    },
    data(){
        return {
            selectIndex:0,//当前显示
            list:[]
        }
    },
    mounted(){
        this.list=[{
            mainName:'私有化知识库',
            name:'及 AI 助理',
            desc:'采用 RAG 中的 Embedding 方案构建知识库，用户可通过手动输入、问答对导入或文本文件直接分段上传数据集，快速转换成智能的搜索或问答服务',
            imgPath:this.$IMG_PUBLIC_PATH + 'website/lun-agent.png'
            },
            {
                mainName:'Wrokflow',
                name:' 高级编排系统',
                desc:'通过可视化的工具和流程，让大型语言模型驱动的系统不在黑盒中运行，并对其进行可视化的运维、监控、标注和持续优化，使其能够处理更复杂的任务',
                imgPath:this.$IMG_PUBLIC_PATH + 'website/lun-workflow.png'
            },
            {
                mainName:'AI 工作流',
                name:' 编排端到端',
                desc:'灵活编排 AI 工作过程，接入企业现有系统工具。实时监控运行状态，让 AI 落地业务场景更可靠',
                imgPath:this.$IMG_PUBLIC_PATH + 'website/lun-bianpai.png'
            }
        ]
    },
    methods:{
        //私有化部署，防轮播
        handlePrivateDeployment(opt,i){
            let len = this.list.length
            if(opt === 'pre'){
                if(i === 0){
                    this.selectIndex = len -1
                }else{
                    this.selectIndex --
                }
            }else{
                if(i === len -1){
                    this.selectIndex = 0
                }else{
                    this.selectIndex ++ 
                }
            }
        }
    }
}
</script>
