<!--
 * @Description: 价格方案
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 13:49:13
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-06-28 17:14:34
-->
<template>
  <div class="home">
      <header-nav />
      <div class="home-1">
          <div class="container flex-row justify-between">
              <div class="flex-col">
                  <h1 class="-ml-110">通过<span class="primary-font-color pl-10">红熊AI</span></h1>
                  <h1 class="-mt-38 -ml-110">释放创造力</h1>
                  <span class="mt-30">细分多种产品，为不同客户群体提供对应服务和解决方案</span>
                  <div class="flex-row justify-between tips">
                      <span>· 快速验证</span>
                      <span>· 快速交付</span>
                  </div>
              </div>
              <div class="flex-col">
                  <img :src="$IMG_PUBLIC_PATH + 'rb-illustration%402x.png'" class="rb-illustration" />
              </div>
          </div>
      </div>
      <div class="container flex-row justify-between">
          <div class="flex-col item-box -mt-73">
              <span class="font-main">个人版</span>
              <span class="font-desc mt-15">个人开发者，对AI有一定了解</span>
              <span class="mt-50"><span class="font-price">14999元</span>/年</span>
              <div class="rb-button mt-30">
                  <span>开始使用</span>
              </div>
              <div class="flex-col mt-30 span-box">
                  <span>团队成员 1人</span>
                  <span>构建应用程序 10个</span>
                  <span>向量存储 500MB</span>
                  <span>API接入 支持</span>
                  <span>文件上传限额 50个</span>
                  <span>文件处理优先级 标准</span>
                  <span>消息请求 每天500次</span>
                  <span>批注配额限制 10次</span>
                  <span>日志历史记录 15天</span>
                  <span>自定义工具 不可用</span>
              </div>
          </div>
          <div class="flex-col item-box -mt-73">
              <span class="font-main">SAAS版</span>
              <span class="font-desc mt-15">团队使用版，对AI有一定了解</span>
              <span class="mt-50"><span class="font-price">98000元</span>/年</span>
              <div class="rb-button mt-30">
                  <span>开始使用</span>
              </div>
              <div class="flex-col mt-30 span-box">
                  <span>团队成员 20人</span>
                  <span>构建应用程序 50个</span>
                  <span>向量存储 1000MB</span>
                  <span>API接入 支持</span>
                  <span>文件上传限额 500个</span>
                  <span>文件批量上传 支持</span>
                  <span>文件处理优先级 优先</span>
                  <span>消息请求 无限</span>
                  <span>日志历史记录 无限</span>
                  <span>自定义工具 不限制</span>
              </div>
          </div>
          <div class="flex-col item-box active -mt-73">
              <img :src="$IMG_PUBLIC_PATH + 'website/recommend%402x.png'" class="recommend"/>
              <span class="font-main">企业版/私有化版</span>
              <span class="font-desc mt-15">团队组织使用者</span>
              <span class="mt-50"><span class="font-price">综合报价</span></span>
              <div class="rb-button active mt-30">
                  <span>开始使用</span>
              </div>
              <div class="flex-col mt-30 span-box">
                  <span>团队成员 无限</span>
                  <span>构建应用程序 无限</span>
                  <span>向量存储 无限</span>
                  <span>文件上传限额 无限</span>
                  <span>文件批量上传 支持</span>
                  <span>文件处理优先级 优先</span>
                  <span>消息请求 无限</span>
                  <span>批注配额限制 无限</span>
                  <span>日志历史记录 无限</span>
                  <span>自定义工具 无限</span>
                  <span>知识库 可私有化</span>
              </div>
          </div>
      </div>
      <div class="flex-col container privatized-box mt-60">
          <h2>私有化版</h2>
          <div class="flex-row justify-between">
              <span>适合有产品，具备集成能力但缺乏 AI 能力的技术公司</span>
              <div class="flex-col">
                  <span class="flex-row items-center justify-start"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">包含企业版所有功能</span></span>
                  <span class="flex-row items-center justify-start mt-40"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">模型可靠的商业级平台</span></span>
                  
              </div>
              <div class="flex-col">
                  <span class="flex-row items-center justify-start"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">快速验证、快速交付</span></span>
                  <span class="flex-row items-center justify-start mt-40"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">成功落地的行业经验和运营经验</span></span>
              </div>
          </div>
          <div class="flex-row justify-between items-start">
              <div class="flex-row items-center">
                 <button class="black-button">咨询报价</button>
              </div>
              <img :src="$IMG_PUBLIC_PATH + 'website/logo-shading%402x.png'" class="logo-shading-small"/>
          </div>
      </div>
      <div class="flex-col container justify-center mt-80">
          <span class="fs-36">客户说</span>
          <span class="fs-20-thin mt-30">红熊AI涵盖多个领域，收获众多好评</span>
          <div class="flex-row justify-between mt-60">
              <div class="flex-col item-box">
                  <span class="font-desc h-120">“作为一家B2B建材公司，我们一直在寻找能够帮助我们提升业务效率的解决方案。红熊AI不仅提供了强大的AI工具，还帮助我们实现了业务流程的自动化。现在，我们能够更快地响应客户需求，提升了客户满意度。”</span>
                  <div class="flex-row justify-between mt-40">
                      <img :src="$IMG_PUBLIC_PATH + 'website/shading-1%402x.png'" class="icon-56-36"/>
                      <span>— — 五京科技CEO</span>
                  </div>
              </div>
              <div class="flex-col item-box">
                  <span class="font-desc h-120">“红熊AI平台使我们的运营效率得到了质的飞跃。通过AI分析和自动化工具，我们可以更好地管理和优化我们的业务流程，降低运营成本，提高了整体运营效率。”</span>
                  <div class="flex-row justify-between mt-40">
                      <img :src="$IMG_PUBLIC_PATH + 'website/shading-1%402x.png'" class="icon-56-36"/>
                      <span>— — 慢慢跑运营总监</span>
                  </div>
              </div>
              <div class="flex-col item-box">
                  <span class="font-desc h-120">“在投资领域，数据的准确性和分析的深度至关重要。红熊AI的强大分析工具帮助我们更好地理解市场动态，制定更精确的投资策略，提升了客户满意度和投资回报率。” </span>
                  <div class="flex-row justify-between mt-40">
                      <img :src="$IMG_PUBLIC_PATH + 'website/shading-1%402x.png'" class="icon-56-36"/>
                      <span>— — 睿趣商务咨询CEO</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="flex-row container justify-between mt-80">
          <div class="question-item-box flex-row items-start justify-between">
              <span class="fs-30">常见问题</span>
              <img :src="$IMG_PUBLIC_PATH + 'website/arrow-question%402x.png'" class="icon-28 mt-10" />
              <img :src="$IMG_PUBLIC_PATH + 'website/question%402x.png'" class="icon-question" />
          </div>
          <div class="h-400-box flex-col">
              <div class="flex-col item-box-q items-start">
                  <span class="font-main">除了提供 AI 开发工具平台，红熊 AI 还提供哪些服务？</span>
                  <span class="font-desc mt-10">红熊 AI 还提供包括 AI 应用落地培训，AI 工作流定制，模型微调在内的多种服务，满足用户的多样化需求</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">红熊 AI 相较于市面上常见的 Chat 类工具有什么区别？</span>
                  <span class="font-desc mt-10">红熊 AI 专注于提供定制化的企业级解决方案，包括专业业务工作流构建培训，以适应特定行业的垂直需求。</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">如何快速开发一个 AI项目？</span>
                  <span class="font-desc mt-10">红熊 AI 通过 Agent 工作流模式，用户根据自己的业务需求快速匹配和完成项目管理</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">红熊 AI 是否支持定制化开发？</span>
                  <span class="font-desc mt-10">红熊 AI 提供定制化开发服务，以适应不同垂直化场景的特定需求。</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">红熊 AI 是如何计费的？</span>
                  <span class="font-desc mt-10">提供灵活的服务方式，包括私有化部署和 SAAS（软件即服务）模式，计费标准根据服务内容和项目规模定制化设定。</span>
              </div>
          </div>
      </div>
      <regbox-footer class="mt-60"/>
      <airb-footer class="mt-60"/>
  </div>
</template>

<script>
// @ is an alias to /src
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'
export default {
  name: 'HomeView',
  components: {
    headerNav,airbFooter,regboxFooter
  }
}
</script>
<style lang="less" scoped>
.rb-illustration{
    width: 442px;
    height: 470px;
}
</style>
